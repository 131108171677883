import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import { Link } from 'react-router-dom'



class Card extends Component {


    constructor(props) {
        super(props);
       


     
        this.onWheel = this.onWheel.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this)
        this.ondragstart = this.ondragstart.bind(this)

    }
    onWheel(event) {
        const delta = Math.max(-1, Math.min(1, (event.nativeEvent.wheelDelta || -event.nativeEvent.detail)))
        event.currentTarget.scrollLeft -= (delta * 10)

    };
    onMouseDown(event) {

        event.preventDefault();
        let x = event.clientX;
        let el = event.currentTarget;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);

        function onMouseMove(event) {

            const delta = Math.max(-1, Math.min(1, event.clientX - x))
            el.scrollLeft -= (delta * 10)
        }

        function onMouseUp() {
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('mousemove', onMouseMove);
        }

    };

    ondragstart(e) {
        return false;
    };







    render() {

        return (
            <div>
                <div id="hidesale" style={{background:"url('/imgs/Framecard.svg') 0 0 no-repeat, url('/imgs/Framecard2.svg') 100% 100% no-repeat"}}>

                    <div className="col-12 m-auto" style={{ textAlign: "center" }}>
                        <img src="/imgs/Ellipse26.svg"></img>
                        <p>Linda Bergman</p>
                        <img style={{
                            marginTop: '5.6vh', maxWidth: '110%',
                            marginLeft: '-14px'
                        }} src="/imgs/badge.svg"></img>
                    </div>

                    <div className="menu_btm col-12">
                        <Link to="/dashboard" refresh='true'><img id="imgs" src="/imgs/Option1.svg"></img></Link>
                        <Link to="/card"><img id="imgs1" src="/imgs/Optionc.svg"></img></Link>
                        <Link to="/market"><img id="imgs2" src="/imgs/Option3.svg"></img></Link>

                    </div>

                </div>

            </div>
        );
    }
}
export default Card

