import React, { Component, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import { Link } from 'react-router-dom'
import $ from 'jquery'



class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = { position: '0' };
        this.change = this.change.bind(this);

        this.onChoose = this.onChoose.bind(this);

        this.onMouseDown = this.onMouseDown.bind(this)
        this.onTouchStart = this.onTouchStart.bind(this)
        this.ondragstart = this.ondragstart.bind(this)
    }


    componentDidMount() {
        $('.slides')[0].style.marginLeft = '-574px';
        $('.dots #li3').addClass('activedot');
        $('#dashboard')[0].style.minHeight = window.outerHeight + 'px';
    }
    componentDidUpdate() {
        $('.slides')[0].style.marginLeft = '-574px';
        $('.dots #li3').addClass('activedot');
        $('.dots #li1').removeClass('activedot');
        $('.dots #li2').removeClass('activedot');
        $('.dots #li4').removeClass('activedot');
        $('.dots #li5').removeClass('activedot');
        $('#dashboard')[0].style.minHeight = window.outerHeight + 'px';
    }

    onMouseDown(event) {
        event.preventDefault();
        let x = event.clientX;
        let el = event.currentTarget;

        let y = event.clientY;

        document.addEventListener('mousemove', OnMouseMove);
        document.addEventListener('mouseup', OnMouseUp);
        let i = 1;

        for (let li of $('.level')) {
            li.style.position = 'relative';
            li.insertAdjacentHTML('beforeend', `<span style="position:absolute;left:0;top:0;display:none">${i}</span>`);
            i++;
        }
        /* конфигурация */
        let widthB = 287; // ширина картинки
        let countB = 1; // видимое количество изображений

        let listElemsB = $('.level');

        let position = Number($('.slides')[0].style.marginLeft.replace('px', ''));
        let k = 0;

        function OnMouseMove(event) {

            const deltaY = Math.max(-1, Math.min(1, event.clientY - y));
            if (deltaY == 1 && el.id == "badge1"){
                $('#badge1 img').animate({ height: "0"}, 500);
                $('.top5').show()
            }

            const delta = Math.max(-1, Math.min(1, event.clientX - x));
            el.scrollLeft -= (delta * 10)
            if (k == 0) {
                if (delta == 1) {

                    position += widthB;
                    // последнее передвижение влево может быть не на 3, а на 2 или 1 элемент
                    position = Math.min(position, 0)
                    $('.slides')[0].style.marginLeft = position + 'px';
                    if (position == 0) {
                        $('.dots #li1').addClass('activedot');
                        $('.dots #li2').removeClass('activedot');
                    }
                    if (position == -287) {
                        $('.dots #li2').addClass('activedot');
                        $('.dots #li3').removeClass('activedot');
                    }
                    if (position == -574) {
                        $('.dots #li3').addClass('activedot');
                        $('.dots #li4').removeClass('activedot');
                    }
                    if (position == -861) {
                        $('.dots #li4').addClass('activedot');
                        $('.dots #li5').removeClass('activedot');
                    }
                    k++;


                }
                else {
                    position -= widthB;
                    // последнее передвижение вправо может быть не на 3, а на 2 или 1 элемент
                    position = Math.max(position, -(widthB) * (listElemsB.length - countB));

                    $('.slides')[0].style.marginLeft = position + 'px'

                    if (position == -287) {
                        $('.dots #li2').addClass('activedot');
                        $('.dots #li1').removeClass('activedot');
                    }
                    if (position == -574) {
                        $('.dots #li3').addClass('activedot');
                        $('.dots #li2').removeClass('activedot');
                    }
                    if (position == -861) {
                        $('.dots #li4').addClass('activedot');
                        $('.dots #li3').removeClass('activedot');
                    }
                    if (position == -1148) {
                        $('.dots #li5').addClass('activedot');
                        $('.dots #li4').removeClass('activedot');
                    }
                    k++;

                }

            }

        }

        function OnMouseUp() {
            document.removeEventListener('mouseup', OnMouseUp);
            document.removeEventListener('mousemove', OnMouseMove);

        }



    };
    onTouchStart(event) {


        let x = event.touches[0].clientX;
        let y = event.touches[0].clientY;
        let el = event.currentTarget;

        document.addEventListener('touchmove', onTouchMove);
        document.addEventListener('touchend', onTouchEnd);
        let i = 1;

        for (let li of $('.level')) {
            li.style.position = 'relative';
            li.insertAdjacentHTML('beforeend', `<span style="position:absolute;left:0;top:0;display:none">${i}</span>`);
            i++;
        }
        /* конфигурация */
        let widthB = 287; // ширина картинки
        let countB = 1; // видимое количество изображений

        let listElemsB = $('.level');

        let position = Number($('.slides')[0].style.marginLeft.replace('px', ''));
        let k = 0;

        function onTouchMove(event) {

            const delta = Math.max(-1, Math.min(1, event.touches[0].clientX - x));
            const deltaY = Math.max(-1, Math.min(1, event.touches[0].clientY - y));
            if (deltaY == 1 && el.id == "badge1"){
                $('#badge1 img').animate({ height: "0"}, 500);
                $('.top5').show()
            }

            if (k == 0) {
                if (delta == 1) {

                    position += widthB;
                    // последнее передвижение влево может быть не на 3, а на 2 или 1 элемент
                    position = Math.min(position, 0)
                    $('.slides')[0].style.marginLeft = position + 'px';
                    if (position == 0) {
                        $('.dots #li1').addClass('activedot');
                        $('.dots #li2').removeClass('activedot');
                    }
                    if (position == -287) {
                        $('.dots #li2').addClass('activedot');
                        $('.dots #li3').removeClass('activedot');
                    }
                    if (position == -574) {
                        $('.dots #li3').addClass('activedot');
                        $('.dots #li4').removeClass('activedot');
                    }
                    if (position == -861) {
                        $('.dots #li4').addClass('activedot');
                        $('.dots #li5').removeClass('activedot');
                    }
                    k++;


                }
                else {
                    position -= widthB;
                    // последнее передвижение вправо может быть не на 3, а на 2 или 1 элемент
                    position = Math.max(position, -(widthB) * (listElemsB.length - countB));

                    $('.slides')[0].style.marginLeft = position + 'px'

                    if (position == -287) {
                        $('.dots #li2').addClass('activedot');
                        $('.dots #li1').removeClass('activedot');
                    }
                    if (position == -574) {
                        $('.dots #li3').addClass('activedot');
                        $('.dots #li2').removeClass('activedot');
                    }
                    if (position == -861) {
                        $('.dots #li4').addClass('activedot');
                        $('.dots #li3').removeClass('activedot');
                    }
                    if (position == -1148) {
                        $('.dots #li5').addClass('activedot');
                        $('.dots #li4').removeClass('activedot');
                    }
                    k++;

                }

            }

        }

        function onTouchEnd() {
            document.removeEventListener('touchend', onTouchEnd);
            document.removeEventListener('touchmove', onTouchMove);

        }



    };

    ondragstart(e) {
        return false;
    };



    change(e) {

        $('.active')[0].className = $('.active')[0].className.replace(" active", "");

        e.target.className += " active";

        if (e.target.id === '1') {
            $('.sal').show();
            $('.awards').hide();
            $('.top5').hide();
            $('#toptxt1').show();
            $('#toptxt2').hide();
        }
        if (e.target.id === '2') {
            $('.sal').hide();
            $('.awards').show();
            $('.top5').hide();
            $('#toptxt1').hide();
            $('#toptxt2').show();
        }
        if (e.target.id === '3') {
            $('.sal').hide();
            $('.awards').hide();
            $('.top5').show();
            $('#toptxt1').hide();
            $('#toptxt2').show();
        }

    }

    onChoose(e) {
        $('#badge1 img').animate({ height: "71%"}, 300);
        $('.top5').hide();

    }



    render() {
        return (
            <div id="dashboard" style={{ background: "url('imgs/Frame.png') 100% 0 no-repeat, url('imgs/Group.png') 0 100% no-repeat, #F2F4F7", backgroundBlendMode: "multiply", overflowY: 'hidden' }}>
                <div className="toptxt">
                    <p id="toptxt1">You are better than 86% of users!</p>
                    <p id="toptxt2">You are in the TOP 1 000 of users!</p>
                </div>
                <div className="col-12" style={{ margin: '10px auto 0 auto', display: 'inline-block', textAlign: 'center' }}>
                    <img style={{ width: '140px' }} src="/imgs/Frame45.png"></img>
                    <img style={{ width: '140px' }} src="/imgs/Frame44.svg"></img>
                </div>
                <ul className="navbar-nav col-10">
                    <li className="nav-item active" onClick={this.change} >
                        <a className="nav-link" href="#level" id="1">Level</a>
                    </li>
                    <li className="nav-item" onClick={this.change} >
                        <a className="nav-link" href="#aw" id="2">Badges</a>
                    </li>
                    <li className="nav-item" onClick={this.change} >
                        <a className="nav-link" href="#t5" id="3">History</a>
                    </li>
                </ul>


                <div className="sal col-10" >
                    <div id="scroll" style={{ overflow: "auto" }} onMouseDown={this.onMouseDown} onDragStart={this.ondragstart} onTouchStart={this.onTouchStart}>
                        <div id="sliderdots" className="slider-dots">
                            <div className="slides">
                                <div className="level" id="1">
                                    <img style={{ marginLeft: '10px' }} src="/imgs/level21.svg"></img>
                                </div>
                                <div className="level" id="2">
                                    <img src="/imgs/level22.svg"></img>
                                </div>
                                <div className="level" id="3">
                                    <img style={{marginRight: '140px'}} src="/imgs/level23.svg"></img>
                                </div>
                                <div className="level" id="4" style={{display: 'contents'}}>
                                    <img style={{width:'104px', height: '102px', margin: 'auto 170px auto auto'}} src="/imgs/level4.png"></img>
                                </div>
                                <div className="level" id="5" style={{display: 'contents'}}>
                                    <img style={{width:'104px', height: '102px', margin: 'auto 160px auto auto'}} src="/imgs/level5.png"></img>
                                </div>
                            </div>
                            <img style={{ margin: '16px auto 9.2vw auto', width: '100%' }} src="/imgs/statusbar.svg"></img>
                            <ul className="dots">
                                <li id="li1"></li>
                                <li id="li2"></li>
                                <li id="li3"></li>
                                <li id="li4"></li>
                                <li id="li5"></li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="menu_btm col-12">
                    <Link to="/dashboard"><img id="imgs" src="/imgs/Optiond.svg"></img></Link>
                    <Link to="/card"><img id="imgs1" src="/imgs/Option2.svg"></img></Link>
                    <Link to="/market"><img id="imgs2" src="/imgs/Option3.svg"></img></Link>
                </div>

                <div className="awards" onWheel={this.onWheel} onMouseDown={this.onMouseDown} onDragStart={this.ondragstart}>
                    <img src="/imgs/Frame47.svg"></img>
                </div>
                <div className="top5" onWheel={this.onWheel} onMouseDown={this.onMouseDown} onDragStart={this.ondragstart}>
                    <p>Plants</p>
                    <img src="/imgs/Frame48.png"></img>
                    <button className="col-10 m-auto" onClick={this.onChoose}>Other spends</button>
                </div>
                <div id="badge1"  onMouseDown={this.onMouseDown} onDragStart={this.ondragstart} onTouchStart={this.onTouchStart}>
                    <img src="imgs/history.png"></img>
                </div>


            </div>
        );
    }
}
export default Dashboard





