import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './components/App';
import Dashboard from './components/Dashboard';
import Market from './components/Market';
import Registration from './components/Registration';
import Card from './components/Card';
import Badge2 from './components/Badges/Badge2';




ReactDOM.render((

    <Router>
        <Route exact path="/" component={App} />
        <Route path="/login" component={Registration} />
        <Route path='/dashboard' component={Dashboard}/>
        <Route path='/card' component={Card}/>
        <Route path='/market' component={Market}/>
        <Route path="/badge"  component={Badge2}/>
       
    </Router>
),
    document.getElementById('rootIn'))