import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import Badge2 from '../Badges/Badge2'


class Market extends Component {
    constructor(props) {
        super(props);
        this.state = { col: 0, id: "", show: "false" };

        this.onChoose = this.onChoose.bind(this);
        this.showBadge = this.showBadge.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this)
        this.ondragstart = this.ondragstart.bind(this)

    }
    onWheel(event) {
        const delta = Math.max(-1, Math.min(1, (event.nativeEvent.wheelDelta || -event.nativeEvent.detail)))
        event.currentTarget.scrollLeft -= (delta * 10)

    };
    onMouseDown(event) {

        event.preventDefault();
        let x = event.clientX;
        let el = event.currentTarget;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);

        function onMouseMove(event) {

            const delta = Math.max(-1, Math.min(1, event.clientX - x))
            el.scrollLeft -= (delta * 10)
        }

        function onMouseUp() {
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('mousemove', onMouseMove);
        }

    };

    ondragstart(e) {
        return false;
    };



    updateData = (value) => {
        this.setState({ col: this.state.col + value })
    }

    onChoose(e) {
        $('#badge').fadeToggle();

        $('#hidemarket').hide();
        if (e.target.id === '1') {
            this.setState({ id: '1' });

        }
        if (e.target.id === '2') {
            this.setState({ id: '2' })
        }
        if (e.target.id === '3') {
            this.setState({ id: '3' })
        }
        if (e.target.id === '4') {
            this.setState({ id: '4' })
        }
        if (e.target.id === '5') {
            this.setState({ id: '5' })
        }
        if (e.target.id === '6') {
            this.setState({ id: '6' })
        }

        this.setState({ show: "true" });

    }

    showBadge() {
        if (this.state.show === "true") {
            return <Badge2 id={this.state.id} updateData={this.updateData} />

        }

    }



    render() {

        return (
            <div>
                <div id="hidemarket"  style={{background:"url('/imgs/Framemarket.svg') 0 0 no-repeat, url('/imgs/Groupback.png') 100% 100% no-repeat"}}>
                    <div className="row col-12 m-auto">
                        <p className="col-8" style={{ marginBottom: "0" }}>Market</p>
                        <div className="basket col-4">
                            <div id="img">
                                <img src="/imgs/basket.png"></img>
                            </div>

                            <p id="basket-col">{this.state.col}</p>
                        </div>

                    </div>

                    <p className="category col-11 m-auto">Coupons</p>
                    <div className="voucher col-11" onWheel={this.onWheel} onMouseDown={this.onMouseDown} onDragStart={this.ondragstart}>
                        <img src="/imgs/badgem1.png" id='1' onClick={this.onChoose} ></img>
                        <img src="/imgs/badgem2.png" id='2' onClick={this.onChoose} ></img>
                        <img src="/imgs/badgem3.png" id='3' onClick={this.onChoose} ></img>
                    </div>

                    <p className="category col-11 m-auto">Gifts</p>
                    <div className="gift col-11" onWheel={this.onWheel} onMouseDown={this.onMouseDown} onDragStart={this.ondragstart}>
                        <img src="/imgs/badgem4.png" id='4' onClick={this.onChoose} ></img>
                        <img src="/imgs/badgem5.png" id='5' onClick={this.onChoose}></img>
                        <img src="/imgs/badgem6.png" id='6' onClick={this.onChoose}></img>
                    </div>

                    <div className="menu_btm col-12">
                        <Link to="/dashboard" refresh='true'><img id="imgs" src="/imgs/Option1.svg"></img></Link>
                        <Link to="/card"><img id="imgs1" src="/imgs/Option2.svg"></img></Link>
                        <Link to="/market"><img id="imgs2" src="/imgs/Optionm.svg"></img></Link>

                    </div>

                </div>
                <div id="badge" style={{ display: "none", background: "#FFFFFF" }}>
                    {this.showBadge()}
                </div>

            </div>
        );
    }
}
export default Market
